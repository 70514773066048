import React, {
  useEffect,
  useState,
  createContext,
  useCallback,
  lazy,
  Suspense,
  useRef,
} from "react";
import "./App.css";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Toolbar from "./components/shared/Toolbar";
import axios from "axios";
import { v4 as uuid } from "uuid";
import Cookie from "js-cookie";
import Loading from "./components/shared/Loading";
import ReactGA from "react-ga";
import fetchRestaurant from "./controllers/fetchRestaurant";
import Discover from "./pages/Discover";
import DiscoverToolbar from "./components/shared/DiscoverToolbar";

// Lazy load your route components
const Menu = lazy(() => import("./pages/Menu"));
const ScrollMenu = lazy(() => import("./pages/ScrollMenu"));
const BetaAdmin = lazy(() => import("./pages/BetaAdmin"));
const LandingPage = lazy(() => import("./pages/LandingPage"));

export const AppContext = createContext();

function App() {
  const params = useParams();

  const [sections, setSections] = useState([]);
  const [activeSection, setActiveSection] = useState("popular");
  const firstLoad = useRef(true);
  const [searchState, setSearchState] = useState();
  const [popular, setPopular] = useState([]);

  const [focusedId, setFocusedId] = useState();
  const [activeToolbar, setActiveToolbar] = useState(false);

  const [originalRestaurant, setOriginalRestaurant] = useState([]);
  const [restaurantId, setRestaurantId] = useState("");

  const [name, setName] = useState("platefol");
  const [theme, setTheme] = useState("platefol");
  const [secondaryTheme, setSecondaryTheme] = useState("platefol");
  const [color, setColor] = useState("#263523");
  const [google, setGoogle] = useState("");
  const [logo, setLogo] = useState("");
  const [location, setLocation] = useState("");

  const CustomerRoutes = () => {
    return (
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Suspense>
    );
  };

  const DiscoverRoutes = () => {
    return (
      <>
        <DiscoverToolbar />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="*" element={<Discover />} />
          </Routes>
        </Suspense>
      </>
    );
  };

  const MenuRoutes = () => {
    return (
      <>
        <Suspense fallback={<Loading />}>
          <Toolbar />

          <Routes>
            <Route exact path="/discover" element={<Discover />} />
            <Route
              path="/:resId"
              element={
                <Menu
                  searchFilter={searchFilter}
                  data={sections}
                  popular={originalRestaurant}
                />
              }
            />
            <Route
              path="/menuItems/:resId/:itemId"
              element={<ScrollMenu data={sections} />}
            />
            <Route
              path="/admin/:id"
              element={<BetaAdmin data={[...popular, ...sections]} />}
            />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </Suspense>
      </>
    );
  };

  const searchFilter = (value) => {
    if (value.trim() === "" || !value) {
      setSections(originalRestaurant);
    } else {
      let mutableCopy = Array.from(originalRestaurant);
      let filteredSections = mutableCopy.map((section) => {
        let filteredItems = section.items.filter((item) => {
          if (item.title.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        });

        return {
          ...section,
          items: filteredItems,
        };
      });
      setSections((prevState) => {
        if (filteredSections !== prevState) {
          return filteredSections;
        }
      });
    }
  };

  useEffect(() => {
    // Add Google Analytics to the app for tracking, but set that to the google variable in the restaurant state
    if (google) {
      ReactGA.initialize(google);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <AppContext.Provider
          value={{
            sections: sections,
            setSections: setSections,
            setOriginalRestaurant: setOriginalRestaurant,
            focusedId: focusedId,
            setFocusedId: setFocusedId,
            activeToolbar: activeToolbar,
            activeSection: activeSection,
            setActiveSection: setActiveSection,
            setActiveToolbar: setActiveToolbar,
            firstLoad: firstLoad,
            popular: popular,
            setPopular: setPopular,
            name: name,
            setName: setName,
            theme: theme,
            setTheme: setTheme,
            secondaryTheme: secondaryTheme,
            setSecondaryTheme: setSecondaryTheme,
            color: color,
            setColor: setColor,
            google: google,
            setGoogle: setGoogle,
            logo: logo,
            setLogo: setLogo,
            location: location,
            setLocation: setLocation,
            restaurantId: restaurantId,
            setRestaurantId: setRestaurantId,
          }}
        >
          <Routes>
            <Route path="/discover" element={<DiscoverRoutes />} />
            <Route path="/r/*" element={<MenuRoutes />} />
            <Route exact path="/" element={<CustomerRoutes />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AppContext.Provider>
      </div>
    </Router>
  );
}

export default React.memo(App);
