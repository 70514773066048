import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./ScrollMenu.css";
import { AppContext } from "../App";
import axios from "axios";
import "../components/shared/Themes.css";
import fetchRestaurant from "../controllers/fetchRestaurant";
import debounce from "lodash/debounce";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
const ImgFile = lazy(() => import("../components/shared/ImgFile"));
const AutoPlaySilentVideo = (props) => {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <ReactPlayer
      className={props.className}
      ref={videoRef}
      loop
      playing={props.startPlaying}
      muted
      playsinline={true}
      url={[{ src: props.src, type: "video/mp4" }]}
      width="100%"
      height="100%"
      controls={false}
      alt={props.alt}
      config={{
        file: {
          attributes: {
            controlsList: "nofullscreen",
          },
        },
      }}
    ></ReactPlayer>
  );
};

const NoContent = (props) => {
  const fileInput = useRef(null);

  const chooseFile = () => {
    fileInput.current.click();
  };

  return (
    <div className="no-content-background">
      <div className="no-content">
        <div className="no-content-prompt">
          <div className="oops">Oops!</div>
          <div className="upload-description">
            Looks like {props.restaurantName} hasn’t uploaded any content for
            this item. Provide your own photos or video of the {props.itemName}{" "}
            and we'll dish it up.
          </div>
        </div>
      </div>
    </div>
  );
};

const SecondaryActions = (props) => {
  const fileInput = useRef(null);

  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);

  const chooseFile = () => {
    fileInput.current.click();
  };

  const attachFile = (e) => {
    e.preventDefault();

    // Retrieve the files from the input element
    const files = e.target.files;

    if (!files || files.length === 0) {
      console.error("No files found.");
      return;
    }

    // Read the file for preview
    const file = files[0];

    // Read the file as a base64 string for content
    const readerForContent = new FileReader();
    readerForContent.readAsDataURL(file);
    readerForContent.onloadend = () => {
      const base64String = readerForContent.result.split(",")[1]; // Remove the data URL prefix
      // Here you can prepare the base64 string to be sent to the database

      // Post Body
      const postBody = {
        contentType: file.type,
        data: base64String,
        restaurantId: props.restaurantId,
        itemId: props.itemId,
      };

      // Set uploading to true
      setUploading(true);

      // Post to the server
      axios
        .post(
          `${process.env.REACT_APP_DOMAIN}/api/restaurant/edit/pending-media`,
          postBody
        )
        .then((res) => {
          console.log(res);
        })
        .then(() => {
          setUploading(false);
          setUploaded(true);
        })
        .catch((err) => {
          console.error(err);
        });
    };
  };

  return (
    <div className="secondary-actions">
      <button
        className={`action-button upload-button${uploaded ? " saved" : ""}`}
        onClick={chooseFile}
      >
        {uploading ? "Uploading..." : uploaded ? "Saved!" : "Upload"}
      </button>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .gif, .mp4"
        onChange={attachFile}
      />
    </div>
  );
};

const Discover = () => {
  const [menuItems, setMenuItems] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [items, setItems] = useState([]);
  const [inView, setInView] = useState(null);
  const [seen, setSeen] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const itemRefs = useRef([]);
  const navigate = useNavigate();

  // Get lat and lng from the query string
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const lat = params.get("lat");
  const lng = params.get("lng");

  useEffect(() => {
    if (lat && lng) {
      axios
        .post(
          `${process.env.REACT_APP_DOMAIN}/api/restaurant/discover-by-lat-long`,
          {
            lat,
            lng,
          }
        )
        .then((res) => {
          setMenuItems(res.data);
          setItems(res.data);
          setLoaded(true);
          setLatitude(lat);
          setLongitude(lng);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Request the user's location
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        axios
          .post(
            `${process.env.REACT_APP_DOMAIN}/api/restaurant/discover-by-lat-long`,
            {
              lat: latitude,
              lng: longitude,
            }
          )
          .then((res) => {
            setMenuItems(res.data);
            setItems(res.data);
            setLoaded(true);
            setLatitude(latitude);
            setLongitude(longitude);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }, [lat, lng]);

  const context = useContext(AppContext);

  useEffect(() => {
    setExpanded(false);
    document.querySelectorAll("video").forEach((video) => {
      video.muted = true;
      video.currentTime = 0;
    });
  }, [inView]);

  useEffect(() => {
    document.querySelector("#theme-color").content = "#000";
    document.body.style.backgroundColor = "#000";
  }, []);

  const resetExpanded = (e) => {
    e.stopPropagation();
    setExpanded(false);
  };

  const expandItem = (e) => {
    e.stopPropagation();
    setExpanded((prevState) => !prevState);
  };

  const pauseVideo = (e) => {
    e.stopPropagation();
    document.querySelectorAll("video").forEach((video) => {
      video.pause();
    });
  };

  const unpauseVideo = (e) => {
    e.stopPropagation();
    document.querySelectorAll("video").forEach((video) => {
      video.play();
    });
  };

  const goToRestaurantMenu = (e) => {
    e.stopPropagation();

    // Encode Current URL to return to
    const currentUrl =
      encodeURIComponent(window.location.pathname) +
      encodeURIComponent(window.location.search);

    navigate(
      `/r/${e.target.getAttribute(
        "restaurant"
      )}?discover=true&lat=${latitude}&lng=${longitude}&returnto=${inView}`
    );
  };

  const openMaps = async (e) => {
    e.stopPropagation();

    const encodedAddress = encodeURIComponent(e.target.getAttribute("address"));
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let mapsUrl;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      mapsUrl = `https://maps.apple.com/?daddr=${encodedAddress}`;
    } else if (/android/i.test(userAgent)) {
      mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    } else {
      mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    }

    await window.open(mapsUrl, "_blank");

    await axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/restaurant/edit/referral`,
      {
        resId: e.target.getAttribute("restaurant"),
      }
    );
  };

  useEffect(() => {
    const options = {
      threshold: 0.5,
    };
    const debouncedIntersectionCallback = debounce((entries) => {
      const indicesInView = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => itemRefs.current.indexOf(entry.target));

      const indicesPlusNext = indicesInView
        .map((index) =>
          index + 1 < items.length ? [index, index + 1] : [index]
        )
        .flat();

      // Add indices to seen
      setSeen((prev) => [
        ...prev,
        ...indicesPlusNext,
        ...indicesPlusNext.map((index) => index + 1),
      ]);
      setInView(...indicesPlusNext);
    }, 100);
    const observer = new IntersectionObserver(
      debouncedIntersectionCallback,
      options
    );
    itemRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });
    return () => {
      observer.disconnect();
      debouncedIntersectionCallback.cancel();
    };
  }, [items]);

  useEffect(() => {
    // Extract 'returnto' parameter from the query string
    const searchParams = new URLSearchParams(window.location.search);
    const returnTo = searchParams.get("returnto");

    if (returnTo != null) {
      // Get the element to scroll into view
      const element = document.querySelectorAll(`.scrollItem`)[returnTo];

      if (element) {
        // Scroll the element into view
        element.scrollIntoView({
          behavior: "instant",
          block: "start",
        });
      }
    }
  }, [items]);

  return (
    <div className={`ScrollMenu${" bounce-signal"}`}>
      {menuItems &&
        menuItems.map((item, index) => (
          <div
            key={item._id}
            onClick={resetExpanded}
            onTouchStart={pauseVideo}
            onTouchEnd={unpauseVideo}
            id={`item-${item._id}`}
            className={`scrollItem`}
            ref={(element) => {
              itemRefs.current[index] = element;
            }}
          >
            {item.content.src != "/" ? (
              item.content.contentType === "img" ? (
                seen.includes(index) ? (
                  <>
                    <img
                      src={item.content.thumbnail}
                      className="item-image loading ken-burns"
                    />
                    <ImgFile
                      expanded={index === inView && expanded ? true : false}
                      src={item.content.src}
                      alt={item.title}
                    />
                  </>
                ) : null
              ) : seen.includes(index) ? (
                <>
                  <img
                    src={item.content.thumbnail}
                    className="item-image loading"
                  />
                  <AutoPlaySilentVideo
                    className={`item-image${
                      index === inView && expanded ? " expanded-gradient" : ""
                    }`}
                    src={item.content.src}
                    autoPlay
                    startPlaying={index === inView ? true : false}
                  />
                </>
              ) : null
            ) : (
              <NoContent
                restaurantName={item.restaurantName}
                itemName={item.title}
              />
            )}
            <div className="gradient">
              <div className="item-content" onClick={expandItem}>
                <div className="item-price">
                  ${item.price.toFixed(2)} | {item.restaurantName}
                </div>
                <div className={`jost item-title`}>{item.title}</div>

                <div
                  className={`item-description${
                    index == inView && expanded ? " expanded" : " collapsed"
                  }`}
                >
                  {item.description}
                </div>
              </div>
              <div className="action-buttons">
                <div className="primary-actions">
                  <div className="button-section">
                    <a
                      onClick={goToRestaurantMenu}
                      itemId={item._id}
                      restaurant={item.id}
                      className="action-button menu-button"
                    >
                      See Menu
                    </a>
                  </div>
                  <div className="button-section">
                    <a
                      onClick={openMaps}
                      address={item.location}
                      restaurant={item.id}
                      className="action-button map-button"
                    >
                      Go Here
                    </a>
                  </div>
                </div>
                {item.content.src == "/" && (
                  <SecondaryActions
                    restaurantId={item.id}
                    itemId={item.itemId}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default React.memo(Discover);
